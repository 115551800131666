.footer {
  position: absolute;
  text-align: center;
  padding: 1rem;
  margin-right: auto;
  margin-left: auto;
  bottom: 0;
}
.footer strong {
  font-size: 20px;
  line-height: 26px;
}

.footer p {
  font-size: 14px;
  line-height: 18px;
  color: #8c8c8c;
  margin: 0;
}

.footer small {
  font-size: 12px;
  line-height: 14px;
  color: #8c8c8c;
  margin: 0;
}

.footer a {
  text-decoration: none;
}
