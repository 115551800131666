.searchBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.countrySelector {
  max-width: 150px;
}

.search-content {
  max-width: 500px;
}

.search-content small {
  font-size: 12px;
  line-height: 18px;
  color: #8c8c8c;
  margin: 0;
}

.filtersContainer {
  padding: 1rem;
}
.ion-text-wrap p {
  white-space: pre-wrap;
}

.cardAvatar {
  background-color: #ccc;
}
