.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%); */
  margin: 0 20px;
}

.open-content {
  text-align: center;
  max-width: 300px;
}

.open-content small {
  font-size: 12px;
  line-height: 18px;
  color: #8c8c8c;
  margin: 0;
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}
.phoneContainer {
  margin: 1rem auto;
  text-align: start;
  max-width: 300px;
}

[popover].popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}
